import React, { useRef } from "react";
import Header from "../../elements/Header/Header";
// basics
import Button from "../../basics/Button/Button";
// elements
import Footer from "../../elements/Footer/Footer";
import HeroImage from "../../../resources/images/constructionSite.jpg";
import StepsContainer from "../../elements/StepsContainer/StepsContainer";
// pics
import IndustryImage from "../../../resources/images/industry.jpeg";
import ImmoImage from "../../../resources/images/immo.jpeg";
import ConstructionImage from "../../../resources/images/construction.jpeg";
// styles
import styles from "./HomePage.module.css";
import ContactContainer from "../../elements/ContactContainer/ContactContainer";

const HomePage = () => {
  const contactRef = useRef(null);

  const scroll = (ref) => {
    ref?.current?.scrollIntoView();
  };

  return (
    <div className="page">
      <Header />
      <div className={`${styles.heroContainer}`}>
        <div className={`${styles.heroContent}`}>
          <div className="container">
            <h2>Asbestinventarisatie</h2>
            <p>
              Een asbestinventaris is eigenlijk een uitgebreid document dat een
              overzicht geeft van alle asbesthoudende materialen in een gebouw
              of werkzone. De asbestinventaris wordt altijd opgesteld nadat wij
              zijn langsgekomen om een inspectie ter plaatse te doen. Tijdens de
              inspectie gaan we actief op zoek naar asbesthoudende materialen.
              Deze worden netjes in kaart gebracht en voor elk asbesthoudend
              materiaal geeft de asbestinventaris ook een gericht advies om het
              asbest veilig te beheren of te verwijderen.
            </p>
            <p>
              Vandaag zijn er twee soorten asbestinventarissen: De{" "}
              <strong>destructieve</strong> en de{" "}
              <strong>niet-destructieve</strong> asbestinventaris
            </p>
            <Button
              label="Maak een afspraak"
              onClick={() => scroll(contactRef)}
            />
          </div>
        </div>
        <div className={`${styles.heroOverlay}`}></div>
        <img src={HeroImage} alt="hero_img" />
      </div>

      <section
        className={`${styles.sectionContainer} ${styles.info}`}
        id="info"
      >
        <StepsContainer />
      </section>

      <section id="werkwijze">
        <div className="container">
          <h2>Werkwijze</h2>
          <span className="subtitle">01. Vooronderzoek</span>
          <p>
            Het uitvoeren van een vooronderzoek is altijd nodig en zorgt ervoor
            dat wij efficiënter te werk kunnen gaan. Bij Asbestoplossingen
            voeren wij een grondig vooronderzoek uit zodat we nadien op een
            praktische manier aan de slag kunnen gaan.
          </p>
          <span className="subtitle">02. Veldonderzoek</span>
          <p>
            Tijdens het ingeplande veldonderzoek komen wij langs op locatie om
            de situatie te bekijken. Ook nemen wij stukjes met asbest (monsters)
            mee naar een laboratorium voor nader onderzoek. Deze informatie
            vormt de basis voor het asbestinventarisatierapport.
          </p>
          <span className="subtitle">03. Asbestinventarisatierapport</span>
          <p>
            Door onze ervaring en efficiënte werkwijze weten wij snel en
            concreet uw situatie in kaart te brengen. De juiste foto’s, goede
            omschrijvingen en volledigheid zorgen ervoor dat onze rapporten
            veelal snel worden aangeleverd en aanvullend onderzoek niet nodig
            is.
          </p>

          <div className="row">
            <div className={`${styles.serviceContent}`}>
              <div className={`${styles.seriviceImageWrapper}`}>
                <img src={IndustryImage} alt="service" />
              </div>
              <div className={`${styles.seriviceTextWrapper}`}>
                <h3>Industrie en bedrijven</h3>
                <span className="subtitle">
                  Eerst inventariseren, dan saneren
                </span>
                <p>
                  Op welke manier uw bedrijf ook te maken krijgt met asbest, u
                  wilt snel weten waar u aan toe bent om verdere stappen te
                  kunnen ondernemen. Asbestoplossingen is specialist in het
                  uitvoeren van asbestinventarisaties en het opstellen van
                  inventarisatierapporten.
                </p>
                <p>
                  Omdat wij de omvang van aanwezig asbest snel en effectief in
                  kaart brengen, kan asbest nagenoeg altijd snel gesaneerd
                  worden op basis van deze rapporten.
                </p>
                <p>
                  Inventariseren en rapporteren doen wij accuraat maar wel
                  efficiënt. Maximaal resultaat is voor ons, net als voor u, het
                  allerbelangrijkste.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className={`${styles.serviceContent} ${styles.textLeft}`}>
              <div className={`${styles.seriviceImageWrapper}`}>
                <img src={ImmoImage} alt="service" />
              </div>
              <div className={`${styles.seriviceTextWrapper}`}>
                <h3>Immo en vastgoed</h3>
                <span className="subtitle">
                  Snelle en daadkrachtige inventarisatierapporten
                </span>
                <p>
                  Bij de aan- of verkoop van huizen kan u asbest ontdekken.
                  Asbestoplossingen helpt u graag met een asbestinventarisatie
                  of een asbestattest. Met meer dan 1.000
                  asbestinventarisatierapporten achter onze naam, zijn wij uw
                  proactieve partner die u zonder zorgen verder helpt.
                </p>
                <p>
                  Als specialist binnen dit vakgebied denken wij bovendien op
                  een praktische manier met u mee, inventariseren de problemen
                  op een duidelijke manier en kijken wat nodig is, zodat u snel
                  weer verder kan.
                </p>
                <p>
                  Werkt u graag op een efficiënte, duidelijke manier en houdt u
                  van rapportages die snel worden goedgekeurd?
                </p>
                <p>Contacteer ons dan snel!</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className={`${styles.serviceContent}`}>
              <div className={`${styles.seriviceImageWrapper}`}>
                <img src={ConstructionImage} alt="service" />
              </div>
              <div className={`${styles.seriviceTextWrapper}`}>
                <h3>Constructie sector</h3>
                <span className="subtitle">
                  Snelle en daadkrachtige inventarisatierapporten
                </span>
                <p>
                  Bij het bouwen en verbouwen van gebouwen is veelvuldig gebruik
                  gemaakt van asbest. De toepassingen waren legio en asbest is
                  terug te vinden in heel veel verschillende toepassingen.
                  Asbestoplossingen heeft zich gespecialiseerd in het uitvoeren
                  van asbestinventarissen en – rapporteringen, specifiek binnen
                  de constructie sector. Als specialist hebben wij talloze
                  inventarisaties uitgevoerd op en rondom bouwsites. Dat scheelt
                  u veel tijd en kosten. Wij denken graag proactief en praktisch
                  met u mee. Bovendien voorzien we u van effectieve rapportages
                  die niet onnodig lang zijn.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        ref={contactRef}
        className={`${styles.sectionContainer}`}
        id="contact"
      >
        <ContactContainer />
      </section>

      <Footer />
    </div>
  );
};

export default HomePage;
