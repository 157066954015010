import React from "react";

import { AiOutlinePhone, AiOutlineCalendar } from "react-icons/ai";
import { MdEditNote } from "react-icons/md";

import styles from "./StepsContainer.module.css";

const StepsContainer = () => {
  return (
    <div className={styles.stepsWrapper}>
      <div className={`container ${styles.stepsContainer}`}>
        <div className="row">
          <div
            className={`col-12 col-md-12 col-lg-4 col-xl-4 ${styles.stepWrapper}`}
          >
            <div className={styles.logoWrapper}>
              <AiOutlinePhone />
            </div>
            <div className={styles.textWrapper}>
              <h3>Vraag uw attest aan</h3>
              <p>
                Bel ons of stuur een mail, en dan nemen wij spoedig contact op
                met u
              </p>
            </div>
          </div>
          <div
            className={`col-12 col-md-12 col-lg-4 col-xl-4 ${styles.stepWrapper}`}
          >
            <div className={styles.logoWrapper}>
              <MdEditNote />
            </div>
            <div className={styles.textWrapper}>
              <h3>Offerte</h3>
              <p>U ontvangt binnen de 24u een offerte</p>
            </div>
          </div>
          <div
            className={`col-12 col-md-12 col-lg-4 col-xl-4 ${styles.stepWrapper}`}
          >
            <div className={styles.logoWrapper}>
              <AiOutlineCalendar />
            </div>
            <div className={styles.textWrapper}>
              <h3>Afspraak</h3>
              <p>
                Op de afgesproken datum komen wij ter plaatse voor de opstart
                van het attest
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepsContainer;
