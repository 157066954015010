import React, { useEffect, useState } from "react";

import styles from "./Header.module.css";

const Header = () => {
  const storedLanguage = localStorage.getItem("language");

  const [lang, setLang] = useState(storedLanguage);

  useEffect(() => {
    if (storedLanguage === null) {
      setLang("nl");
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    localStorage.setItem("language", lang);
  }, [lang]);

  return (
    <div className={`${styles.headerWrapper}`}>
      <div className={`container ${styles.headerContainer}`}>
        <div className="row">
          <div className={`col-12 col-md-12 col-lg-3 col-xl-3`}>
            <></>
            {/* <div className={`${styles.logoWrapper}`}>
              <span>LOGO</span>
            </div> */}
          </div>

          <div className={`col-12 col-md-12 col-lg-6 col-xl-6`}>
            <ul className={`${styles.navWrapper}`}>
              <li>
                <a href="#info">info</a>
              </li>
              <li>
                <a href="#werkwijze">werkwijze</a>
              </li>
              <li>
                <a href="#contact">contact</a>
              </li>
            </ul>
          </div>

          <div className={`col-12 col-md-12 col-lg-3 col-xl-3`}>
            {/* <div className={`${styles.languagesWrapper}`}>
              <span
                onClick={() => setLang("nl")}
                className={lang === "nl" ? styles.activeLang : null}
              >
                nl
              </span>
              <span
                onClick={() => setLang("fr")}
                className={lang === "fr" ? styles.activeLang : null}
              >
                fr
              </span>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
