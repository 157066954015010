import React from "react";

import styles from "./Button.module.css";

const Button = ({
  label,
  style = "primary",
  type = "button",
  disabled = false,
  onClick,
}) => {
  return (
    <button
      disabled={disabled}
      className={`${styles.button} ${styles[style]}`}
      onClick={onClick}
      type={type}
    >
      {label}
    </button>
  );
};

export default Button;
