import React from "react";

import styles from "./Footer.module.css";

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={`container ${styles.footerContainer}`}>
        {/* <div className={styles.logoWrapper}>
          <img className={styles.footerLogo} src={ Logo } alt="site-logo" />
        </div> */}
        {/* <hr className={styles.divider} /> */}
        <div className={styles.footerContact}>
          <a href="mailto:asbestattestdirect@outlook.com">
            asbestattestdirect@outlook.com
          </a>
          <a href="tel:+32499199661">+32 499 19 96 61</a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
